import { useEffect, useState } from "react";
import { getEasyjetDateFlights } from "../Helpers/ApiResponseEasyjet";
import { getRyanairDateFlights } from "../Helpers/ApiResponseRyanair";
import * as Helpers from "../Helpers/Helpers";
import { CustomDropdown } from "../components/CustomDropdown";
import { TableFlights } from "../components/TableFlights";
import { Airline, CustomFlight, DropdownItems } from "../types/CustomTypes";
import { SquirrelButton } from "../components/SquirrelButton";

const SpecificDates = () => {
    const [dateFrom, setdateFrom] = useState<Date>();
    const [dateTo, setdateTo] = useState<Date>();
    const [invalidDateFrom, setinvalidDateFrom] = useState<boolean>();
    const [invalidDateTo, setinvalidDateTo] = useState<boolean>();

    const [sortDeparture, setsortDeparture] = useState<number>(1);
    const [noDecimalsDeparture, setnoDecimalsDeparture] = useState<boolean>(false);

    const [departureSelected, setdepartureSelected] = useState<string>();
    const [listOfIatasDdl, setlistOfIatasDdl] = useState<DropdownItems[]>([]);
    const [flightsForRoute, setflghtsForRoute] = useState<CustomFlight[]>();

    useEffect(() => {
        const getData = async () => {
            setlistOfIatasDdl(await Helpers.getAllIataDdl());
        };

        getData();
    }, []);

    useEffect(() => {
        searchFlights();
        return () => {};
    }, [sortDeparture]);
    

    function changeInputDateFrom(_value: string): void {
        setdateFrom(new Date(_value));
        setinvalidDateFrom(Helpers.isDateBeforeDate(new Date(_value)));
        setinvalidDateTo(Helpers.isDateBeforeDate(dateTo!, new Date(_value)));
    }

    function changeInputDateTo(_value: string): void {
        setdateTo(new Date(_value));
        setinvalidDateTo(Helpers.isDateBeforeDate(new Date(_value), dateFrom));
    }

    const dropdownDepartureChange = (_iataId: string) => {
        setdepartureSelected(_iataId);
    };

    function sortDepartureClick(): void {
        if (sortDeparture < 3) setsortDeparture(sortDeparture + 1);
        else setsortDeparture(1);
    }

    function noDecimalsDepartureClick(): void {
        setnoDecimalsDeparture(!noDecimalsDeparture);
    }

    async function searchFlights() {
        if (departureSelected && dateFrom && dateTo) {
            var _flightsForAllRoutes: CustomFlight[] = [];

            const _ryanairDeparture: CustomFlight[] = await getRyanairDateFlights(departureSelected!, dateFrom!, dateTo!);

            const _easyjetDeparture: CustomFlight[] = await getEasyjetDateFlights(departureSelected!, dateFrom!, dateTo!);

            _flightsForAllRoutes = [..._ryanairDeparture, ..._easyjetDeparture];

            var _flightsForAllRoutesSorted: CustomFlight[] = [];
            switch (sortDeparture) {
                case 2:
                    _flightsForAllRoutesSorted = _flightsForAllRoutes.sort((a, b) => a.Price! - b.Price!);
                    break;

                case 3:
                    _flightsForAllRoutesSorted = _flightsForAllRoutes
                        .sort((a, b) => a.Date.getTime() - b.Date.getTime())
                        .sort((a, b) => (Object.keys(Airline).indexOf(a.Airline) > Object.keys(Airline).indexOf(b.Airline) ? 1 : -1));
                    break;

                default:
                    _flightsForAllRoutesSorted = _flightsForAllRoutes.sort((a, b) => a.Date.getTime() - b.Date.getTime());
                    break;
            }

            setflghtsForRoute(_flightsForAllRoutesSorted.filter((v, i, a) => a.indexOf(v) === i));
        }
    }

    async function searchFlightsClick(_restoreSorting: boolean = false): Promise<void> {
        await searchFlights();

        if (_restoreSorting) setsortDeparture(1);
    }

    return (
        <div className="specificDates" style={{ display: "flex" }}>
            <div>
                <SquirrelButton text="Home" onClick={() => (window.location.href = "/")}></SquirrelButton>
            </div>
            <div style={{ width: "50%" }}>
                <div>
                    <div>
                        <input
                            type="date"
                            id="dateFrom"
                            name="dateFrom"
                            onChange={(event) => changeInputDateFrom(event.target.value)}
                            min={new Date().toISOString().split("T")[0]}
                        ></input>
                        {invalidDateFrom ? <div>invalid date</div> : null}
                    </div>
                    <div>
                        <input
                            type="date"
                            id="dateTo"
                            name="dateTo"
                            onChange={(event) => changeInputDateTo(event.target.value)}
                            min={new Date().toISOString().split("T")[0]}
                        ></input>
                        {invalidDateTo ? <div style={{ color: "red" }}>invalid date</div> : null}
                    </div>
                    <div>
                        <CustomDropdown
                            ddItems={listOfIatasDdl}
                            onChangeDrop={dropdownDepartureChange}
                            watermark="Select Departure Airport"
                        ></CustomDropdown>
                    </div>
                </div>
                <div>
                    <div style={{ width: "100%", display: "flex" }}>
                        {flightsForRoute ? <button onClick={() => sortDepartureClick()}>SORT</button> : null}
                        {flightsForRoute ? (
                            <button onClick={() => noDecimalsDepartureClick()}>{noDecimalsDeparture ? "Decimals" : "No Decimals"}</button>
                        ) : null}
                        <button onClick={() => searchFlightsClick()}>Search</button>
                    </div>
                </div>
                <div style={{ display: "flex", width: "100%" }}>
                    {flightsForRoute ? (
                        <div style={{ width: "50%" }}>
                            <TableFlights
                                flightsforroute={flightsForRoute}
                                sortedcolumn={sortDeparture}
                                nodecimals={noDecimalsDeparture}
                                showLocation={true}
                            ></TableFlights>
                        </div>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default SpecificDates;