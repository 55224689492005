import React, { useState } from "react";
import styled from "styled-components";
import { dateFormatOnly, dateFormatWithTime, isPhoneScreen } from "../Helpers/Constants";
import { getDateInFormat, getDayOfWeek, mediaQuery } from "../Helpers/Helpers";
import { Airline, CustomFlight } from "../types/CustomTypes";

import easyjetImg from "../img/sm/easyjet.svg";
import ryanairImg from "../img/sm/ryanair.png";
import vuelingImg from "../img/sm/vueling.jpeg";

import sortImg from "../img/down-arrow.png";

import "./../styles/tableflights.scss";
import moment from "moment";

interface Props {
    flightsforroute: CustomFlight[];
    nodecimals: boolean;
    sortedcolumn: number;
    showLocation?: boolean;
    onclickflight?: Function;
    timestart?: Date;
    timeend?: Date;
}

const Container = styled.div<{}>`
    width: 100%;
    padding: 5px;
    font-family: monospace;

    ${mediaQuery.tablet} {
    }

    ${mediaQuery.phone} {
        font-size: 11px;
    }
`;

const Table = styled.table<{}>`
    border-spacing: 0px;
    border-collapse: collapse;
    margin: 10px;
    ${mediaQuery.phone} {
        margin: 0px;
    }
`;

const Thead = styled.thead<{}>``;

const Th = styled.th<{ sortby: number }>`
    padding: 5px 15px 5px 5px;
    &:nth-child(1) {
    }
    &:nth-child(2) {
    }
    &:nth-child(3) {
    }
    &:nth-child(${(props) => props.sortby}) {
        color: #717171;
        background-image: url(${sortImg});
        background-size: 15px;
        background-position: right;
        background-repeat: no-repeat;
    }
`;

const Tbody = styled.tbody<{}>``;

const Tr = styled.tr<{}>`
    cursor: pointer;
`;

const Td = styled.td<{ sortby: number }>`
    border: 1px solid blue;
    padding: 5px;
    &:nth-child(1) {
    }
    &:nth-child(2) {
        text-align: right;
    }
    &:nth-child(3) {
    }
    &:nth-child(${(props) => props.sortby}) {
        background-color: #f7f7f7;
    }
`;

const AirlineLogo = styled.div<{ urllogo: Airline }>`
    width: 20px;
    height: 20px;
    background-image: url(${(props) => (props.urllogo ? getAirlineLogo(props.urllogo) : "")});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;
    margin: 1px;
`;

// function mobileDateString(_originalDate: Date) {
//     return _originalDate.toLocaleString("en-us", { weekday: "short" }) + " " + getDateInFormat(_originalDate);
// }
function getDateInString(_flight: CustomFlight) {
    return _flight.DateHasNoTme ? moment(_flight.Date).format(dateFormatOnly) : moment(_flight.Date).format(dateFormatWithTime);
}

function getAirlineLogo(_airline: Airline) {
    switch (_airline) {
        case Airline.Easyjet:
            return easyjetImg;
        case Airline.Ryanair:
            return ryanairImg;
        case Airline.Vueling:
            return vuelingImg;
    }
}

export const TableFlights: React.FC<Props> = ({ flightsforroute, nodecimals, sortedcolumn, showLocation, onclickflight, timestart, timeend }) => {
    const [flightSelected, setflightSelected] = useState<CustomFlight>();

    function flightClick(flight: CustomFlight): void {
        if (flightSelected == flight) setflightSelected(undefined);
        else setflightSelected(flight);

        if (onclickflight) onclickflight(flight);
    }

    return (
        <Container>
            <Table>
                <Thead>
                    <Tr>
                        <Th sortby={sortedcolumn}>Date</Th>

                        {isPhoneScreen ? <Th sortby={sortedcolumn}>€</Th> : <Th sortby={sortedcolumn}>Price</Th>}
                        {isPhoneScreen ? <Th sortby={sortedcolumn}></Th> : <Th sortby={sortedcolumn}>Airline</Th>}

                        {showLocation ? <Th sortby={sortedcolumn}>Destination</Th> : null}
                    </Tr>
                </Thead>

                <Tbody>
                    {flightsforroute
                        ? flightsforroute?.map((flight, index) => {
                              if ((timestart && flight.Date > timestart) || (timeend && flight.Date < timeend)) return null;
                              else
                                  return (
                                      <Tr
                                          key={index}
                                          onClick={(event) => flightClick(flight)}
                                          className={(() => {
                                              if (!flightSelected) {
                                                  return "noFlightSelected";
                                              } else if (flight == flightSelected) {
                                                  return "flightSelected";
                                              } else {
                                                  return "notThisFlightSelected";
                                              }
                                          })()}>
                                          <Td sortby={sortedcolumn}>{isPhoneScreen ? getDayOfWeek(flight.Date) + " " + getDateInFormat(flight.Date) : getDateInString(flight)}</Td>
                                          <Td sortby={sortedcolumn}>{flight.Price?.toFixed(nodecimals ? 0 : 2)}</Td>
                                          {isPhoneScreen ? (
                                              <Td sortby={sortedcolumn}>
                                                  <AirlineLogo urllogo={flight.Airline}></AirlineLogo>
                                              </Td>
                                          ) : (
                                              <Td sortby={sortedcolumn}>{flight.Airline}</Td>
                                          )}
                                          {showLocation ? <Td sortby={sortedcolumn}>{flight.Destination}</Td> : null}
                                      </Tr>
                                  );
                          })
                        : null}
                </Tbody>
            </Table>
        </Container>
    );
};
