import { Airline, CustomTime, DropdownItems, MediaSize } from "../types/CustomTypes";
import { ListOfEuropeanAirportCodes, ListOfEuropeanAirportNames } from "../types/Iatas";
import { ManageEasyjetAirportSessionData, ManageEasyjetRouteSessionData } from "./ApiResponseEasyjet";
import { ManageRyanairAirportSessionData, ManageRyanairRouteSessionData } from "./ApiResponseRyanair";
import { ManageVuelingAirportSessionData, getListOfUniqueArrivalsVueling } from "./ApiResponseVueling";

export function getDateInFormat(_originalDate?: Date, _whatToAdd?: CustomTime, _howMuchToAdd?: number) {
    const _date = _originalDate ?? new Date();
    if (_whatToAdd && _howMuchToAdd) {
        switch (_whatToAdd) {
            //TODO check add date
            case CustomTime.Day:
                const _newDateD = new Date(_date.setDate(_originalDate!.getDate() + _howMuchToAdd));
                return `${_newDateD.getFullYear()}-${(_newDateD.getMonth() + 1).toString().padStart(2, "0")}-${_newDateD.getDate().toString().padStart(2, "0")}`;
            case CustomTime.Month:
                const _newDateM = new Date(_date.setMonth(_date.getMonth() + _howMuchToAdd));
                return `${_newDateM.getFullYear()}-${(_newDateM.getMonth() + 1).toString().padStart(2, "0")}-${_newDateM.getDate().toString().padStart(2, "0")}`;
            case CustomTime.Year:
                return `${_date.getFullYear() + _howMuchToAdd}-${(_date.getMonth() + 1).toString().padStart(2, "0")}-${(_date.getDate() + _howMuchToAdd).toString().padStart(2, "0")}`;
        }
    } else return `${_date.getFullYear()}-${(_date.getMonth() + 1).toString().padStart(2, "0")}-${_date.getDate().toString().padStart(2, "0")}`;
}

export function getYear(_originalDate?: Date) {
    const _date = _originalDate ?? new Date();
    return _date.getFullYear();
}

export function getMonthNumber(_originalDate?: Date) {
    const _date = _originalDate ?? new Date();
    return _date.getMonth() + 1;
}

export function getNextMonths(_numberOfMonths: number) {
    const _currentDate = new Date();
    const _currentMonth = _currentDate.getMonth() + 1;
    var _nextMonthsList: number[] = [];

    for (let index = 0; index < _numberOfMonths; index++) {
        const _monthToAdd = _currentMonth + index;
        _monthToAdd <= 12 ? _nextMonthsList.push(_monthToAdd) : _nextMonthsList.push(_monthToAdd - 12);
    }
    return _nextMonthsList;
}

export function getDayOfWeek(_originalDate?: Date) {
    const _date = _originalDate ?? new Date();

    return _date.toDateString().split(" ")[0];
}

export function getDaysBetweenDates(_date1: Date, _date2: Date) {
    var diff = Math.abs(_date1.getTime() - _date2.getTime());
    var diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    return diffDays;
}

export function delay(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getFirstDayOfThisMonth(): Date {
    const _currentYear = new Date().getFullYear();
    const _currentMonth = new Date().getMonth();

    return new Date(_currentYear, _currentMonth, 1);
}

export function getDaysRemainingInThisMonth(): number[] {
    const _currentYear = new Date().getFullYear();
    const _currentMonth = new Date().getMonth();
    const _currentDay = new Date().getDate();
    const _currentMonthDays = new Date(_currentYear, _currentMonth + 1, 0).getDate();
    const _daysRemainingInMonth = _currentMonthDays - _currentDay + 1;

    const _output = [];
    for (let index = 1; index <= _daysRemainingInMonth; index++) {
        _output.push(index);
    }

    return _output;
}

export function stringFormat(template: string, ...args: any[]) {
    return template.replace(/{(\d+)}/g, function (match, number) {
        return typeof args[number] != "undefined" ? args[number] : match;
    });
}

export function GetDdlItems(_value: string[], _label: string[]) {
    var _itemListDDL: DropdownItems[] = [];

    for (let index = 0; index < _value.length; index++) {
        var _itemDDL: DropdownItems = {
            label: _label[index],
            value: _value[index],
        };
        _itemListDDL.push(_itemDDL);
    }

    return _itemListDDL.sort((a, b) => a.label.localeCompare(b.label));
}

export async function checkIfRouteBelongsToAirlines(_departureAirportCode: string, _arrivalAirportCode: string) {
    var _airlinesForRoute: Airline[] = [];

    const _ryanairSessionData = await ManageRyanairRouteSessionData(_departureAirportCode);
    if (_ryanairSessionData) {
        const _ryanairRoute = _ryanairSessionData.find((a) => a.arrivalAirport.code === _arrivalAirportCode);
        if (_ryanairRoute) _airlinesForRoute.push(Airline.Ryanair);
    }

    const _easyjetSessionData = await ManageEasyjetRouteSessionData(_departureAirportCode);
    if (_easyjetSessionData) {
        const _easyjetRoute = _easyjetSessionData.Routes.find((a) => a.destination === _arrivalAirportCode);
        if (_easyjetRoute) _airlinesForRoute.push(Airline.Easyjet);
    }

    const _vuelingSessionData = await getListOfUniqueArrivalsVueling(_departureAirportCode);
    if (_vuelingSessionData) {
        const _vuelingRoute = _vuelingSessionData.find((a) => a.code === _arrivalAirportCode);
        if (_vuelingRoute) _airlinesForRoute.push(Airline.Vueling);
    }

    return _airlinesForRoute;
}

export function isDateBeforeDate(_date: Date, _date2?: Date) {
    return _date2 ? _date < _date2 : _date < new Date(new Date().toDateString());
}

export async function getAllIataDdl() {
    const _ryanairAiportResponse = await ManageRyanairAirportSessionData();
    const _ryanairIatas = _ryanairAiportResponse.map((a) => a.code);
    const _ryanairNames = _ryanairAiportResponse.map((a) => a.name);

    //
    const _easyjetAiportResponse = await ManageEasyjetAirportSessionData();
    const _listOfEasyjetIatas = _easyjetAiportResponse.Flights.filter((obj1, i, arr) => arr.findIndex((obj2) => obj2.OriginIata === obj1.OriginIata) === i).map((a) => a.OriginIata);
    const _listOfEasyjetNames: string[] = [];
    _listOfEasyjetIatas.forEach((iata) => {
        if (ListOfEuropeanAirportCodes.indexOf(iata) === -1) {
            _listOfEasyjetNames.push(iata);
        } else {
            _listOfEasyjetNames.push(ListOfEuropeanAirportNames[ListOfEuropeanAirportCodes.indexOf(iata)]);
        }
    });
    const _easyjetIatas = _listOfEasyjetIatas;
    const _easyjetNames = _listOfEasyjetNames;

    //
    const _vuelingAirportResponse = await ManageVuelingAirportSessionData();
    const _vuelingIatas = _vuelingAirportResponse.map((a) => a.Code);
    const _vuelingNames = _vuelingAirportResponse.map((a) => a.Name);

    const _ddlItems1 = GetDdlItems(_ryanairIatas, _ryanairNames);
    const _ddlItems2 = GetDdlItems(_easyjetIatas, _easyjetNames);
    const _ddlItems3 = GetDdlItems(_vuelingIatas, _vuelingNames);

    const _ddlItemsFull = [..._ddlItems1, ..._ddlItems2, ..._ddlItems3];
    const _ddlItemsUnique = _ddlItemsFull.filter((obj1, i, arr) => arr.findIndex((obj2) => obj2.value === obj1.value) === i).sort((a, b) => a.label.localeCompare(b.label));

    return _ddlItemsUnique;
}

export function addTimeToDate(_originalDate: Date, _whatToAdd: CustomTime, _howMuchToAdd: number) {
    switch (_whatToAdd) {
        case CustomTime.Day:
            const _newDateD = new Date(_originalDate.setDate(_originalDate!.getDate() + _howMuchToAdd));
            return _newDateD;
        case CustomTime.Month:
            const _newDateM = new Date(_originalDate.setMonth(_originalDate.getMonth() + _howMuchToAdd));
            return _newDateM;
        case CustomTime.Year:
            const _newDateY = new Date(_originalDate.setFullYear(_originalDate.getFullYear() + _howMuchToAdd));
            return _newDateY;
    }
}

const customMediaQuery = (maxWidth: number) => `@media (max-width: ${maxWidth}px)`;

export const mediaQuery = {
    custom: customMediaQuery,
    tablet: customMediaQuery(MediaSize.tablet),
    phone: customMediaQuery(MediaSize.phone),
};
