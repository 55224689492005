import styled from "styled-components";
import { mediaQuery } from "../Helpers/Helpers";
import easyjetImg from "../img/easyjet1.svg";
import ryanairImg from "../img/ryanair1.svg";
import vuelingImg from "../img/vueling1.svg";

const Header = styled.div<{}>`
    display: flex;
    width: 100%;
`;

const Container = styled.div<{ comingsoon?: string }>`
    display: flex;
    margin: 0px 5px;
    opacity: ${(props) => (props.comingsoon === "true" ? 0.2 : 1)};
    width: 100%;

    ${mediaQuery.tablet} {
    }

    ${mediaQuery.phone} {
        margin: 0px;
    }
`;

const Wrapper = styled.div<{}>`
    padding: 5px;
    width: 100%;
`;

const AirlineLogo = styled.div<{ urllogo?: string }>`
    width: auto;
    height: 70px;
    background-image: url(${(props) => (props.urllogo ? props.urllogo : "")});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 5%;
    margin: 25px;

    ${mediaQuery.tablet} {
        height: 40px;
        margin: 0px;
    }

    ${mediaQuery.phone} {
        height: 30px;
        margin: 0px;
    }
`;

export const HomeHeader = () => {
    return (
        <>
            <Header>
                <Container>
                    <Wrapper>
                        <AirlineLogo urllogo={easyjetImg}></AirlineLogo>
                    </Wrapper>
                    <Wrapper>
                        <AirlineLogo urllogo={ryanairImg}></AirlineLogo>
                    </Wrapper>
                    <Wrapper>
                        <AirlineLogo urllogo={vuelingImg}></AirlineLogo>
                    </Wrapper>
                </Container>
            </Header>
        </>
    );
};
