import moment from "moment";
import { Airline, ArrivalAirport, CustomFlight } from "../types/CustomTypes";
import { VuelingResponseAirport, VuelingResponseAllFlights, VuelingResponseDestination, VuelingResponseFlights } from "../types/VuelingResponseTypes";
import { getApiDataGeneric, getApiDataVueling } from "./ApiData";
import { apiDelay, currency, vuelingAirportUrl, vuelingAllDestinations, vuelingAllDestinationsKEY, vuelingAllDestinationsMomentDateFormat, vuelingArrivalUrl, vuelingRouteUrl } from "./Constants";
import { delay, getMonthNumber, getYear, stringFormat } from "./Helpers";

export async function ManageVuelingAirportSessionData(): Promise<VuelingResponseAirport> {
    var _output: VuelingResponseAirport;
    const storedSession = sessionStorage.getItem("VuelingAirportResponse");

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as VuelingResponseAirport;
    } else {
        _output = await getApiDataGeneric(vuelingAirportUrl);
        sessionStorage.setItem("VuelingAirportResponse", JSON.stringify(_output));
    }
    return _output;
}

export async function ManageVuelingDestinationSessionData(): Promise<VuelingResponseDestination> {
    var _output: VuelingResponseDestination;
    const storedSession = sessionStorage.getItem("VuelingDestinationResponse");

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as VuelingResponseDestination;
    } else {
        _output = await getApiDataGeneric(vuelingArrivalUrl);
        sessionStorage.setItem("VuelingDestinationResponse", JSON.stringify(_output));
    }
    return _output;
}

export async function getListOfUniqueArrivalsVueling(_departureSelected: string) {
    var _listOfArrivals: ArrivalAirport[] = [];
    const _vuelingAirportResponse = await ManageVuelingAirportSessionData();
    const _vuelingDestinationResponse = await ManageVuelingDestinationSessionData();

    const _isVuelingAirport = _vuelingAirportResponse.find((a) => a.Code === _departureSelected);

    if (_isVuelingAirport) {
        const _destinationList = _vuelingDestinationResponse[_departureSelected];

        _destinationList.forEach((element) => {
            // if (!element.Connection) {
            var _VuelingCustomAirport: ArrivalAirport = {
                code: element.DestinationCode,
                name: _vuelingAirportResponse.find((a) => a.Code === element.DestinationCode)!.Name,
            };
            _listOfArrivals.push(_VuelingCustomAirport);
            // }
        });
    }

    const _listOfArrivalsUnique = _listOfArrivals.filter((obj1, i, arr) => arr.findIndex((obj2) => obj2.code === obj1.code) === i);

    return _listOfArrivalsUnique;
}

//

export async function ManageVuelingFlightsSessionData(_departureVueling: string, _arrivalVueling: string) {
    var _output: VuelingResponseFlights;
    const sessionID = "VuelingFlightResponse" + _departureVueling + _arrivalVueling;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as VuelingResponseFlights;
    } else {
        const _urlVuelingFormatted = stringFormat(vuelingRouteUrl, _departureVueling, _arrivalVueling, getYear(), getMonthNumber());

        _output = await getApiDataVueling(_urlVuelingFormatted);

        if (_output) {
            try {
                sessionStorage.setItem(sessionID, JSON.stringify(_output));
            } catch (err) {
                console.log("sessionStorage error");
            }
        }
    }
    return _output;
}

export async function getVuelingFlights(_departure: string, _arrival: string): Promise<CustomFlight[]> {
    const _vuelingFlightResponse = await ManageVuelingFlightsSessionData(_departure, _arrival);
    var _output: CustomFlight[] = [];

    _vuelingFlightResponse.forEach((element) => {
        if (element.Price) {
            var _customFlight: CustomFlight = {
                Date: new Date(element.DepartureDate),
                Destination: element.ArrivalStation,
                Origin: element.DepartureStation,
                Price: element.Price,
                Currency: currency,
                Airline: Airline.Vueling,
            };
            _output.push(_customFlight);
        }
    });

    return _output!;
}

export async function ManageVuelingAllFlightsSessionData(_departureSelected: string) {
    var _output: CustomFlight[] = [];
    const sessionID = "VuelingAllFlightsResponse" + _departureSelected;
    const storedSession = sessionStorage.getItem(sessionID);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as CustomFlight[];
    } else {
        const _urlVuelingFormatted = stringFormat(vuelingAllDestinations, _departureSelected, moment(Date()).format(vuelingAllDestinationsMomentDateFormat));
        const _vuelingDestinations = await getListOfUniqueArrivalsVueling(_departureSelected);

        const _allAirports = await ManageVuelingAirportSessionData();
        const _duplicatedAirports = _allAirports.filter((a) => a.Code === a.MacCode);

        var _urlVuelingFormattedWithDestinations: string = _urlVuelingFormatted;
        if (_vuelingDestinations.length > 0) {
            for (const destination of _vuelingDestinations) {
                const _thisDestinationIsDuplicate = _duplicatedAirports.find((a) => a.Code === destination.code);
                if (!_thisDestinationIsDuplicate) {
                    _urlVuelingFormattedWithDestinations = _urlVuelingFormattedWithDestinations.concat(stringFormat(vuelingAllDestinationsKEY, destination.code));
                }
            }

            const _vuelingResponseAllFlights: VuelingResponseAllFlights = await getApiDataVueling(_urlVuelingFormattedWithDestinations);
            await Promise.all(
                _vuelingResponseAllFlights.map(async (flight) => {
                    _output.push({
                        Date: new Date(flight.DepartureDate),
                        Destination: flight.DestinationName,
                        Origin: flight.OriginCode,
                        Price: flight.IntegerPartPrice,
                        Currency: currency,
                        Airline: Airline.Vueling,
                    });
                })
            );
        }

        if (_output) sessionStorage.setItem(sessionID, JSON.stringify(_output));
    }

    return _output!;
}

export async function getVuelingAllFlights(_departureSelected: string) {
    const _allFlightsFromSession = await ManageVuelingAllFlightsSessionData(_departureSelected);
    var _output: CustomFlight[] = [];

    if (_allFlightsFromSession.length > 0) {
        await Promise.all(
            _allFlightsFromSession.map(async (flight) => {
                _output.push({
                    Date: new Date(flight.Date),
                    Destination: flight.Destination,
                    Origin: flight.Origin,
                    Price: flight.Price,
                    Currency: flight.Currency,
                    Airline: flight.Airline,
                });
            })
        );
    }

    return _output;
}

export async function getVuelingDateFlights(_departureSelected: string, _departureDate: Date, _arrivalDate: Date) {}
