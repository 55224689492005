export type Temp = {};

export interface DropdownItems {
    label: string;
    value: string;
}

export interface ArrivalAirport {
    code: string;
    name: string;
}

export enum Airline {
    Ryanair = "Ryanair",
    Easyjet = "Easyjet",
    Vueling = "Vueling",
}

export interface CustomFlight {
    Price?: number;
    Origin: string;
    Destination: string;
    Date: Date;
    Currency?: string;
    Airline: Airline;
    DateHasNoTme?: boolean;
}

export enum CustomTime {
    Day = "D",
    Month = "M",
    Year = "Y",
}

export enum CustomWeekday {
    sunday = 0,
    monday = 1,
    tuesday = 2,
    wednesday = 3,
    thursday = 4,
    friday = 5,
    saturday = 6,
}

export enum MediaSize {
    phone = 480, 
    tablet = 1024,
}
