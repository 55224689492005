import styled from "styled-components";
import { getDaysBetweenDates, mediaQuery } from "../Helpers/Helpers";
import { CustomFlight } from "../types/CustomTypes";

interface Props {
    departureflight: CustomFlight;
    arrivalflight: CustomFlight;
}

const Container = styled.div<{}>`
    padding: 5px;
    margin: 10px 5px;

    ${mediaQuery.tablet} {
    }

    ${mediaQuery.phone} {
        width: 100%;
        text-align: center;
        margin: 20px;
    }
`;

const Wrapper = styled.div<{}>`
    ${mediaQuery.tablet} {
    }

    ${mediaQuery.phone} {
    }
`;

const Length = styled.span<{}>`
    ${mediaQuery.tablet} {
    }

    ${mediaQuery.phone} {
    }
`;

const Price = styled.span<{}>`
    ${mediaQuery.tablet} {
    }

    ${mediaQuery.phone} {
    }
`;

export const FlightSelection: React.FC<Props> = ({ departureflight, arrivalflight }) => {
    function getDuration() {
        return getDaysBetweenDates(departureflight.Date, arrivalflight.Date);
    }

    function getPrice() {
        return (departureflight.Price! + arrivalflight.Price!).toFixed(2);
    }

    return (
        <>
            <Container>
                <Wrapper>
                    <Length> {getDuration()} days</Length> - <Price> {getPrice()}€ </Price>
                </Wrapper>
            </Container>
        </>
    );
};
