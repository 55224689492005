export const ListOfEuropeanAirportNames: string[] = [
    "Strasbourg",
    "Tromsø",
    "Sitia, Greece",
    "Salerno Costa d'Amalfi",
    "Sal, Cape Verde",
    "Djerba–Zarzis",
    "Aberdeen",
    "Lanzarote",
    "Izmir",
    "Akureyri",
    "Agadir",
    "Malaga",
    "Alghero",
    "Ajaccio",
    "Alicante",
    "Amsterdam",
    "Ancona",
    "Arlanda, Stockholm",
    "Athens",
    "Antalya",
    "Barcelona",
    "Brindisi",
    "Belgrade",
    "Berlin",
    "Brest",
    "Belfast",
    "Bergamo/Orio al Serio, Milan",
    "George Best City, Belfast",
    "Birmingham",
    "Bastia",
    "Bilbao",
    "Biarritz",
    "Bodrum",
    "Bologna",
    "Bordeaux",
    "Bournemouth",
    "Burgas",
    "Bari",
    "Bristol",
    "Brussels",
    "EuroAirport Swiss, Mulhouse/Basel",
    "Budapest",
    "Beauvais-Tille, Paris",
    "Cagliari",
    "Charles de Gaulle, Paris",
    "Kerkyra",
    "Chania",
    "Comiso",
    "Calvi",
    "Copenhagen",
    "Catania",
    "Dubrovnik",
    "Dalaman",
    "Edinburgh",
    "Kefallinia",
    "Essaouira",
    "Faro",
    "Fiumicino, Rome",
    "Friedrichshafen",
    "Funchal",
    "Figari",
    "Fuerteventura",
    "Gibraltar",
    "Glasgow",
    "Grenoble-Isere, Lyon",
    "Geneva",
    "Hamburg",
    "Irakleion",
    "Hurghada",
    "Ibiza",
    "Innsbruck",
    "Inverness",
    "Isle of Man",
    "Istanbul",
    "Jersey",
    "Mykonos",
    "Skiathos",
    "Thira",
    "Keflavik, Reykjavik",
    "Kos",
    "Kalamata",
    "Krakow",
    "Kittila",
    "Leeds/Bradford",
    "Larnaca",
    "A Coruna",
    "Lourdes/Tarbes",
    "Almeria",
    "Gatwick, London",
    "Limoges",
    "Lille",
    "Linate, Milan",
    "Lisbon",
    "Ljubljana",
    "Lampedusa",
    "Gran Canaria",
    "Liverpool",
    "La Rochelle",
    "Luton, London",
    "Luxembourg",
    "Saint Exupery, Lyon",
    "Madrid",
    "Menorca",
    "Manchester",
    "Malta",
    "Montpellier",
    "Marseille",
    "Munich",
    "Malpensa, Milan",
    "Naples",
    "Enfidha",
    "Nice",
    "Newcastle",
    "Newquay",
    "Nantes",
    "Olbia",
    "Porto",
    "Orly, Paris",
    "Paphos",
    "Palma de Mallorca",
    "Palermo",
    "Prague",
    "Pristina",
    "Pisa",
    "Pula",
    "Preveza/Lefkada",
    "Porto Santo",
    "Marrakech",
    "Rabat",
    "Reus",
    "Rhodes",
    "Rijeka",
    "Marsa Alam",
    "Corvera",
    "Rennes",
    "Rovaniemi",
    "Santiago de Compostela",
    "Southend, London",
    "Thessaloniki",
    "Skopje",
    "Sofia",
    "Southampton",
    "Santa Cruz de la Palma",
    "Split",
    "Giza",
    "Sharm el-Sheikh",
    "Stansted, London",
    "Lamezia Terme",
    "Sevilla",
    "Salzburg",
    "Tenerife-Sur",
    "Tirana",
    "Tivat",
    "Toulon/Hyeres",
    "Toulouse",
    "Tel Aviv-Yafo",
    "Turin",
    "Marco Polo, Venice",
    "Valencia",
    "Verona",
    "Zadar",
    "Zurich",
    "Zakynthos Island",
];

export const ListOfEuropeanAirportCodes: string[] = [
    "SXB",
    "TOS",
    "JSH",
    "QSR",
    "SID",
    "DJE",
    "ABZ",
    "ACE",
    "ADB",
    "AEY",
    "AGA",
    "AGP",
    "AHO",
    "AJA",
    "ALC",
    "AMS",
    "AOI",
    "ARN",
    "ATH",
    "AYT",
    "BCN",
    "BDS",
    "BEG",
    "BER",
    "BES",
    "BFS",
    "BGY",
    "BHD",
    "BHX",
    "BIA",
    "BIO",
    "BIQ",
    "BJV",
    "BLQ",
    "BOD",
    "BOH",
    "BOJ",
    "BRI",
    "BRS",
    "BRU",
    "BSL",
    "BUD",
    "BVA",
    "CAG",
    "CDG",
    "CFU",
    "CHQ",
    "CIY",
    "CLY",
    "CPH",
    "CTA",
    "DBV",
    "DLM",
    "EDI",
    "EFL",
    "ESU",
    "FAO",
    "FCO",
    "FDH",
    "FNC",
    "FSC",
    "FUE",
    "GIB",
    "GLA",
    "GNB",
    "GVA",
    "HAM",
    "HER",
    "HRG",
    "IBZ",
    "INN",
    "INV",
    "IOM",
    "IST",
    "JER",
    "JMK",
    "JSI",
    "JTR",
    "KEF",
    "KGS",
    "KLX",
    "KRK",
    "KTT",
    "LBA",
    "LCA",
    "LCG",
    "LDE",
    "LEI",
    "LGW",
    "LIG",
    "LIL",
    "LIN",
    "LIS",
    "LJU",
    "LMP",
    "LPA",
    "LPL",
    "LRH",
    "LTN",
    "LUX",
    "LYS",
    "MAD",
    "MAH",
    "MAN",
    "MLA",
    "MPL",
    "MRS",
    "MUC",
    "MXP",
    "NAP",
    "NBE",
    "NCE",
    "NCL",
    "NQY",
    "NTE",
    "OLB",
    "OPO",
    "ORY",
    "PFO",
    "PMI",
    "PMO",
    "PRG",
    "PRN",
    "PSA",
    "PUY",
    "PVK",
    "PXO",
    "RAK",
    "RBA",
    "REU",
    "RHO",
    "RJK",
    "RMF",
    "RMU",
    "RNS",
    "RVN",
    "SCQ",
    "SEN",
    "SKG",
    "SKP",
    "SOF",
    "SOU",
    "SPC",
    "SPU",
    "SPX",
    "SSH",
    "STN",
    "SUF",
    "SVQ",
    "SZG",
    "TFS",
    "TIA",
    "TIV",
    "TLN",
    "TLS",
    "TLV",
    "TRN",
    "VCE",
    "VLC",
    "VRN",
    "ZAD",
    "ZRH",
    "ZTH",
];
