import { Airline, ArrivalAirport, CustomFlight, CustomTime } from "../types/CustomTypes";
import { RyanairResponseAirport, RyanairResponseAllFlights, RyanairResponseFlights, RyanairResponseRoute } from "../types/RyanairResponseTypes";
import { getApiDataGeneric } from "./ApiData";
import { apiDelay, currency, ryanairAirportUrl, ryanairAllDestinations, ryanairArrivalUrl, ryanairRouteUrl } from "./Constants";
import { addTimeToDate, delay, getDateInFormat, stringFormat } from "./Helpers";

export async function ManageRyanairAirportSessionData(): Promise<RyanairResponseAirport> {
    var _output: RyanairResponseAirport;
    const storedSession = sessionStorage.getItem("ryanairAirportResponse");

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as RyanairResponseAirport;
    } else {
        _output = await getApiDataGeneric(ryanairAirportUrl);
        sessionStorage.setItem("ryanairAirportResponse", JSON.stringify(_output));
    }
    return _output;
}

export async function ManageRyanairRouteSessionData(_departureRyanair: string, _urlRyanair: string = ""): Promise<RyanairResponseRoute> {
    var _output: RyanairResponseRoute;
    const storedSession = sessionStorage.getItem("ryanairRouteResponse" + _departureRyanair);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as RyanairResponseRoute;
    } else {
        if (_urlRyanair.length > 0) {
            _output = await getApiDataGeneric(_urlRyanair);
            sessionStorage.setItem("ryanairRouteResponse" + _departureRyanair, JSON.stringify(_output));
        }
    }
    return _output!;
}

export async function ManageRyanairFlightDateSessionData(_departureSelected: string, _departureDate1: Date, _departureDate2: Date) {
    var _output: RyanairResponseAllFlights = {} as RyanairResponseAllFlights;
    const _sessionID = "ryanairAllFlightsResponse" + _departureSelected + getDateInFormat(_departureDate1) + getDateInFormat(_departureDate2);
    const storedSession = sessionStorage.getItem(_sessionID);
    const _ryanairUrl = stringFormat(ryanairAllDestinations, _departureSelected, getDateInFormat(_departureDate1), getDateInFormat(_departureDate2));

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as RyanairResponseAllFlights;
    } else {
        _output = await getApiDataGeneric(_ryanairUrl);
        sessionStorage.setItem(_sessionID, JSON.stringify(_output));
    }

    return _output!;
}

export async function getRyanairDateFlights(_departureSelected: string, _departureDate1?: Date, _departureDate2?: Date): Promise<CustomFlight[]> {
    if (!_departureDate1 && !_departureDate2) {
        _departureDate1 = new Date();
        _departureDate2 = addTimeToDate(new Date(), CustomTime.Year, 2);
    }

    const _ryanairDateFlights: RyanairResponseAllFlights = await ManageRyanairFlightDateSessionData(_departureSelected, _departureDate1!, _departureDate2!);

    var _output: CustomFlight[] = [];
    _ryanairDateFlights.fares.forEach((element) => {
        var _customFlight: CustomFlight = {
            Date: new Date(element.outbound.departureDate),
            Destination: element.outbound.arrivalAirport.name,
            Origin: element.outbound.departureAirport.name,
            Price: element.outbound.price.value,
            Currency: currency,
            Airline: Airline.Ryanair,
        };
        _output.push(_customFlight);
    });

    return _output!;
}

export async function getListOfUniqueArrivalsRyanair(_departureSelected: string) {
    var _arrivalURLRyanair = stringFormat(ryanairArrivalUrl, _departureSelected);
    var _listOfArrivals: ArrivalAirport[] = [];

    const _ryanairAirportResponse = await ManageRyanairAirportSessionData();
    const _isRyanairAirport = _ryanairAirportResponse.find((a) => a.code === _departureSelected);
    if (_isRyanairAirport) {
        const _ryanairResponse: RyanairResponseRoute = await ManageRyanairRouteSessionData(_departureSelected!, _arrivalURLRyanair);
        _ryanairResponse.forEach((element) => {
            var _ryanairCustomAirport: ArrivalAirport = {
                code: element.arrivalAirport.code,
                name: element.arrivalAirport.name,
            };
            _listOfArrivals.push(_ryanairCustomAirport);
        });
    }

    const _listOfArrivalsUnique = _listOfArrivals.filter((obj1, i, arr) => arr.findIndex((obj2) => obj2.code === obj1.code) === i);

    return _listOfArrivalsUnique;
}

export async function ManageRyanairDateWithReturnSessionData(
    _departureRyanair: string,
    _arrivalRyanair: string,
    _departureDate1?: Date,
    _departureDate2?: Date,
    _returnDate1?: Date,
    _returnDate2?: Date
): Promise<CustomFlight[]> {
    var _output: CustomFlight[] = {} as CustomFlight[];

    const _dateFrom1 = _departureDate1 ? getDateInFormat(_departureDate1) : getDateInFormat(new Date());
    const _dateFrom2 = _departureDate2 ? getDateInFormat(_departureDate2) : getDateInFormat(addTimeToDate(new Date(), CustomTime.Year, 2));
    const _dateTo1 = _returnDate1 ? getDateInFormat(_returnDate1) : getDateInFormat(new Date());
    const _dateTo2 = _returnDate2 ? getDateInFormat(_returnDate2) : getDateInFormat(addTimeToDate(new Date(), CustomTime.Year, 2));

    const _sessionID = "ryanairDateWithReturnResponse" + _departureRyanair + _arrivalRyanair;
    const storedSession = sessionStorage.getItem(_sessionID);
    const _ryanairDepartureUrl = stringFormat(ryanairAllDestinations, _departureRyanair, _dateFrom1, _dateFrom2);
    const _ryanairReturnUrl = stringFormat(ryanairAllDestinations, _arrivalRyanair, _dateTo1, _dateTo2);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as CustomFlight[];
    } else {
        const _ryanairDepartureResponse: RyanairResponseAllFlights = await getApiDataGeneric(_ryanairDepartureUrl);
        const _ryanairReturnResponse: RyanairResponseAllFlights = await getApiDataGeneric(_ryanairReturnUrl);


        //get all return that fly to departure airport:
        const _flightsForRoute: CustomFlight[] = [];
        await Promise.all(
            _ryanairDepartureResponse.fares.map(async (_fare) => {
                _flightsForRoute.push({
                    Date: new Date(_fare.outbound.departureDate),
                    Destination: _fare.outbound.arrivalAirport.iataCode,
                    Origin: _fare.outbound.departureAirport.iataCode,
                    Price: _fare.outbound.price.value,
                    Currency: currency,
                    Airline: Airline.Ryanair,
                });
            })
        );

        const _flightsReturnForRoute: CustomFlight[] = [];
        await Promise.all(
            _ryanairReturnResponse.fares.map(async (_fare) => {
                if (_fare.outbound.arrivalAirport.iataCode === _departureRyanair) {
                    _flightsReturnForRoute.push({
                        Date: new Date(_fare.outbound.departureDate),
                        Destination: _fare.outbound.arrivalAirport.iataCode,
                        Origin: _fare.outbound.departureAirport.iataCode,
                        Price: _fare.outbound.price.value,
                        Currency: currency,
                        Airline: Airline.Ryanair,
                    });
                }
            })
        );

        _output = [..._flightsForRoute, ..._flightsReturnForRoute];
        sessionStorage.setItem(_sessionID, JSON.stringify(_output));
    }
    return _output!;
}

export async function getRyanairDateWithReturnFlights(_departure: string, _arrival: string): Promise<CustomFlight[]> {
    return await ManageRyanairDateWithReturnSessionData(_departure, _arrival);
}

export async function ManageRyanairFlightsSessionData(_departureRyanair: string, _arrivalRyanair: string): Promise<RyanairResponseFlights[]> {
    var _output: RyanairResponseFlights[] = {} as RyanairResponseFlights[];
    const storedSession = sessionStorage.getItem("ryanairFlightResponse" + _departureRyanair + _arrivalRyanair);

    if (storedSession && storedSession !== "undefined") {
        _output = JSON.parse(storedSession) as RyanairResponseFlights[];
    } else {
        const _next12Months = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];

        const promises = [];

        for (let i = 0; i < _next12Months.length; i++) {
            const url = stringFormat(ryanairRouteUrl, _departureRyanair, _arrivalRyanair, getDateInFormat(new Date(), CustomTime.Month, _next12Months[i]));
            // make an HTTP request for each URL
            const _singleOutput: RyanairResponseFlights = await fetch(url)
                // transform the HTTP response into JSON
                .then((response) => response.json());

            await delay(apiDelay);
            // add the promise to the promises array
            promises.push(_singleOutput);
        }

        // wait for all the promises in the promises array to resolve
        await Promise.all(promises).then((results) => {
            _output = results;
            sessionStorage.setItem("ryanairFlightResponse" + _departureRyanair + _arrivalRyanair, JSON.stringify(results));
        });
    }
    return _output!;
}

export async function getRyanairFlights(_departure: string, _arrival: string): Promise<CustomFlight[]> {
    const _ryanairFlightResponse = await ManageRyanairFlightsSessionData(_departure, _arrival);
    var _output: CustomFlight[] = [];

    _ryanairFlightResponse.forEach((element) => {
        element.outbound?.fares.forEach((element) => {
            const dateToday = new Date();
            const dateFlight = new Date(new Date(element.day));
            if (dateToday < dateFlight && element.price) {
                var _customFlight: CustomFlight = {
                    Date: new Date(element.departureDate),
                    Destination: _arrival,
                    Origin: _departure,
                    Price: element.price?.value,
                    Currency: currency,
                    Airline: Airline.Ryanair,
                };
                _output.push(_customFlight);
            }
        });
    });

    return _output!;
}