import styled from "styled-components";
import loadingImg from "../img/loading.gif";
import { mediaQuery } from "../Helpers/Helpers";

const Container = styled.div<{}>`
    display: flex;
    width: 100%;
    height: 200px;

    ${mediaQuery.tablet} {
    }

    ${mediaQuery.phone} {
    }
`;

const Wrapper = styled.div<{}>`
    width: 100%;
    margin: auto;
`;

const LoadingImg = styled.div<{}>`
    width: 100%;
    height: 100px;
    background-image: url(${loadingImg});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    ${mediaQuery.tablet} {
    }

    ${mediaQuery.phone} {
        height: 50px;
    }
`;

export const Loading = () => {
    return (
        <>
            <Container>
                <Wrapper>
                    <LoadingImg></LoadingImg>
                </Wrapper>
            </Container>
        </>
    );
};
